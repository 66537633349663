.AddCardCoupon {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 400px;
    min-height: 150px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border-radius: 16px;
    color: #333333;
    padding: 10px 20px;
    background-color: rgb(254, 254, 254);
    transition: ease-in-out 600ms;

    &:hover, &:focus {
        transform: scale(1.03);
        transition: ease-in-out 200ms;
    }

    .content {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            height: 100%;
            color: #e31b23;
        }
    }

    aside {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        word-wrap: break-word;

        h2 {
            font-size: 0.8rem;
            font-weight: unset;
        }
    }

    @media (max-width: 768px) {
        width: 250px;
        min-height: 110px;
    }
}