.ActionConfirmModal {
  position: absolute;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  border-radius: 16px;
  color: #333333;
  background-color: rgb(254, 254, 254);

  .containerModal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .actionsButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        

        button {
            display: block;
            border: unset;
            background-color: unset;
            cursor: pointer;
            user-select: none;
            padding: 5px 10px;
            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
            font-size: 1rem;
            border-radius: 16px;

        }

        .confirm {
            background-color: #33b249;
            color: white;
        }

        .cancel {
            background-color: #dd7973;
            color: white;
        }

    }

  }
}