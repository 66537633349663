.Coupons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3vh;
    margin-bottom: 3vh;

    .freeCoupons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .usedCoupons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @media (max-width: 950px) {
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        gap: 30px;
    }
}