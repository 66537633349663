.PasswordDisplay {
    background: rgb(243, 243, 243);
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -11px 43px 11px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    user-select: none;
    border-radius: 20px;
    overflow: hidden;

    h1 {
        color: #333333;
    }
}