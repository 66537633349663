.PasswordInput {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    width: 300px;
    height: 560px;
    padding: 10px 20px;
    border-radius: 16px;
    
    .submit {
        border: unset;
        color: white;
        height: 10%;
        font-size: larger;
        border-radius: 12px;
        user-select: none;
    }

    .active {
        background-color: #33b249;
        cursor: pointer;
    }

    .deactive {
        background-color: rgb(243, 243, 243);
        color: #757575;
        cursor: not-allowed;
    }

    .display {
        height: 20%;
    }

    .numpad {
        height: 80%;
    }

    @media (max-width: 320px) {
        width: 200px;
        height: 335px;
    }

}