.NumericPad {
    height: 100%;
    
    .grid {
        position: relative;
        display: grid; 
        grid-template-columns: 32% 32% 32%; 
        grid-template-rows: 24% 24% 24% 24%; 
        grid-template-areas: 
            ". . ."
            ". . ."
            ". . ."
            "delete zero erase"; 
        text-align: center;
        grid-gap: 1% 1%;
        height: 100%;
            
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            cursor: pointer;
            user-select: none;
            border-radius: 100%;

        }

        .zero {
            grid-area: zero;
        }

        .delete {
            grid-area: delete;
            font-size: 36px;
        }

        .erase {
            grid-area: erase;
        }

    }

}