.CustomButton {
    background-color: #333333;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border: unset;
    cursor: pointer;
    font-size: 1rem;
    color: white;
    border-radius: 16px;
    padding: 5px 10px;
    user-select: none;
}